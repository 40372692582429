import React from "react"
import styled from "styled-components"

import PageWrapper from "../components/PageWrapperNoHeader"

import TipBanner from "../images/things-to-do-while-you-wait/tip-banner.jpg"
import Tip001 from "../images/things-to-do-while-you-wait/tip-001.jpg"
import Tip002 from "../images/things-to-do-while-you-wait/tip-002.jpg"
import Tip003 from "../images/things-to-do-while-you-wait/tip-003.jpg"
import Tip004 from "../images/things-to-do-while-you-wait/tip-004.jpg"
import Tip005 from "../images/things-to-do-while-you-wait/tip-005.jpg"
import Tip006 from "../images/things-to-do-while-you-wait/tip-006.jpg"
import Tip007 from "../images/things-to-do-while-you-wait/tip-007.jpg"
import Tip008 from "../images/things-to-do-while-you-wait/tip-008.jpg"

const ThingsToDoPage = () => (
  <PageWrapper>
    <Container>
      <img src={TipBanner} alt="Things to do while you wait" />
      <p className="subheading">
        Ah, you’re finally getting your album made and it’s time to wait for
        your finished product. So to help you make the most of your 1 hour
        waiting time we’ve rounded up 8 of the best things to while you wait!
      </p>
      <Content>
        <BlockLeft>
          <div>
            <img src={Tip001} alt="Organise your kitchen" />
          </div>
          <div>
            <p className="title">Organise your kitchen</p>
            <p>
              Cleaning the kitchen may seem like a daunting task. Break it up
              into manageable sections, add some great music and you’ll be
              coasting your way through the cleaning task.{" "}
              <a
                href="https://www.buzzfeed.com/emofly/smart-ways-to-clean-your-kitchen-for-spring"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              for some tips to get you started
            </p>
          </div>
        </BlockLeft>
        <hr></hr>
        <BlockRight>
          <div className="blockRight">
            <img
              src={Tip002}
              alt="Get out and experience something new near you"
            />
          </div>
          <div className="blockLeft">
            <p className="title">
              Get out and experience something new near you
            </p>
            <p>
              Each town holds its own with an amazing mix of cultural, outdoor
              and foodie experiences on offer. Get out and start exploring
              places near you- disappointment is unlikely! Start by picking a
              destination{" "}
              <a
                href="https://www.sa-venues.com/things-to-do/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </p>
          </div>
        </BlockRight>
        <hr></hr>
        <BlockLeft>
          <div>
            <img src={Tip003} alt="Read a little" />
          </div>
          <div>
            <p className="title">Read a little.</p>
            <p>
              Be it a book, the paper, a blog, or a magazine. Ten minutes of
              reading a day makes a difference in how you process information.
              Here are some of our favourite go-to’s:
              <br></br>
              <a
                href="https://www.buzzfeed.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buzzfeed
              </a>
              <br></br>
              <a
                href="https://www.bookbub.com/ebook-deals/free-ebooks"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ebooks
              </a>
            </p>
          </div>
        </BlockLeft>
        <hr></hr>
        <BlockRight>
          <div className="blockRight">
            <img src={Tip004} alt="Plan a few meals" />
          </div>
          <div className="blockLeft">
            <p className="title">
              Plan a few meals and start a grocery list of ingredients you’ll
              need.
            </p>
            <p>
              If you need inspiration, think of meals you’ve enjoyed at
              restaurants or friends’ homes. Try looking up some interesting
              recipes on sites like{" "}
              <a
                href="https://tasty.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tasty
              </a>
              . It’s easy to follow and perfect for any home cook.
            </p>
          </div>
        </BlockRight>
        <hr></hr>
        <BlockLeft>
          <div>
            <img src={Tip005} alt="Give your brain a break" />
          </div>
          <div>
            <p className="title">
              Give your brain a break and succumb to the guilty pleasure of
              mindless games.
            </p>
            <p>
              Games like Flappy Bird, Candy Crush, or Temple Run are my personal
              favourite.
            </p>
          </div>
        </BlockLeft>
        <hr></hr>
        <BlockRight>
          <div className="blockRight">
            <img src={Tip006} alt="Organize your photos" />
          </div>
          <div className="blockLeft">
            <p className="title">Organize your photos.</p>
            <p>
              Use waiting time to delete all the blurry, unfocused, drab,
              unflattering, duplicate or otherwise unwanted photos on your
              phone. Then, if you’re still waiting, either upload them to your
              cloud or organize them into folders.
            </p>
          </div>
        </BlockRight>
        <hr></hr>
        <BlockLeft>
          <div>
            <img src={Tip007} alt="Binge watch that series" />
          </div>
          <div>
            <p className="title">
              Binge watch that series you’ve been neglecting.
            </p>
            <p>
              Some our favourite binge worthy series are Mad Men, Game of
              thrones and Stranger Things. But to each his own, there are
              endless options to choose from.
            </p>
          </div>
        </BlockLeft>
        <hr></hr>
        <BlockRight>
          <div className="blockRight">
            <img src={Tip008} alt="Browse YouTube" />
          </div>
          <div className="blockLeft">
            <p className="title">Browse YouTube.com.</p>
            <p>
              YouTube has endless amounts of information and new content is
              constantly being added to the site. Poke around for a while to
              find some good channels- from weird to funny to completely over
              the top, it’s all there!
            </p>
          </div>
        </BlockRight>
      </Content>
    </Container>
  </PageWrapper>
)

const Container = styled.div`
  max-witdh: 100%;
  text-align: center;
  padding: 10px;
  .title {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  img {
    max-width: 100%;
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
    .subheading {
      padding: 100px;
      font-size: 2rem;
    }
  }
`
const Content = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`

const BlockLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding-top: 20px;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }
`
const BlockRight = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding-top: 20px;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
    .blockRight {
      grid-area: 1 / 2 / 3 / 2;
    }
    .blockLeft {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
`

export default ThingsToDoPage
